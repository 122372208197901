














import { Component, Prop, Vue } from 'vue-property-decorator'
import { getLastActiveTeam } from '@/utils/Teams'
import { teamsStore } from '@/store'

@Component({
  components: {
    LeftDrawer: () => import('@/layouts/TheMain/LeftDrawer.vue'),
    Modals: () => import('@/components/Modals/index.vue'),
  },
})
export default class TheError extends Vue {
  @Prop({
    required: false,
    type: String,
  }) routeTeamId!: string

  get teamId (): string {
    if (this.routeTeamId) return this.routeTeamId

    return this.team?.uid ?? ''
  }

  get team () {
    let team
    // If the team has not yet been initialized there will be an exception
    try {
      team = teamsStore.getters.currentTeam
    } catch (e) {
      team = getLastActiveTeam()
    }

    return team
  }
}
